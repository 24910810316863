/* roboto-100normal - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 100;
  src:
    local('Roboto Thin'),
    local('Roboto-Thin'),
    url('./files/roboto-latin-ext-100.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/roboto-latin-ext-100.woff') format('woff'); /* Modern Browsers */
}

/* roboto-100italic - latin */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-display: swap;
  font-weight: 100;
  src:
    local('Roboto Thin Italic'),
    local('Roboto-ThinItalic'),
    url('./files/roboto-latin-ext-100-italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/roboto-latin-ext-100-italic.woff') format('woff'); /* Modern Browsers */
}

/* roboto-300normal - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src:
    local('Roboto Light'),
    local('Roboto-Light'),
    url('./files/roboto-latin-ext-300.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/roboto-latin-ext-300.woff') format('woff'); /* Modern Browsers */
}

/* roboto-300italic - latin */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-display: swap;
  font-weight: 300;
  src:
    local('Roboto Light Italic'),
    local('Roboto-LightItalic'),
    url('./files/roboto-latin-ext-300-italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/roboto-latin-ext-300-italic.woff') format('woff'); /* Modern Browsers */
}

/* roboto-400normal - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src:
    local('Roboto'),
    local('Roboto-Regular'),
    url('./files/roboto-latin-ext-400.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/roboto-latin-ext-400.woff') format('woff'); /* Modern Browsers */
}

/* roboto-400italic - latin */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-display: swap;
  font-weight: 400;
  src:
    local('Roboto Italic'),
    local('Roboto-Italic'),
    url('./files/roboto-latin-ext-400-italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/roboto-latin-ext-400-italic.woff') format('woff'); /* Modern Browsers */
}

/* roboto-500normal - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src:
    local('Roboto Medium'),
    local('Roboto-Medium'),
    url('./files/roboto-latin-ext-500.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/roboto-latin-ext-500.woff') format('woff'); /* Modern Browsers */
}

/* roboto-500italic - latin */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-display: swap;
  font-weight: 500;
  src:
    local('Roboto Medium Italic'),
    local('Roboto-MediumItalic'),
    url('./files/roboto-latin-ext-500-italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/roboto-latin-ext-500-italic.woff') format('woff'); /* Modern Browsers */
}

/* roboto-700normal - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src:
    local('Roboto Bold'),
    local('Roboto-Bold'),
    url('./files/roboto-latin-ext-700.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/roboto-latin-ext-700.woff') format('woff'); /* Modern Browsers */
}

/* roboto-700italic - latin */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-display: swap;
  font-weight: 700;
  src:
    local('Roboto Bold Italic'),
    local('Roboto-BoldItalic'),
    url('./files/roboto-latin-ext-700-italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/roboto-latin-ext-700-italic.woff') format('woff'); /* Modern Browsers */
}

/* roboto-900normal - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 900;
  src:
    local('Roboto Black'),
    local('Roboto-Black'),
    url('./files/roboto-latin-ext-900.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/roboto-latin-ext-900.woff') format('woff'); /* Modern Browsers */
}

/* roboto-900italic - latin */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-display: swap;
  font-weight: 900;
  src:
    local('Roboto Black Italic'),
    local('Roboto-BlackItalic'),
    url('./files/roboto-latin-ext-900-italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/roboto-latin-ext-900-italic.woff') format('woff'); /* Modern Browsers */
}

